html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  font-family: "Segoe UI", "SegoeUI", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  color: #222222; }

h1 {
  font-size: 2.5em;
  font-weight: bold;
  color: #4b4f3c; }

h2 {
  font-size: 2em;
  font-weight: bold;
  color: #688718; }

p {
  padding: 12px  0; }

a, a:hover, a:visited {
  color: #222222; }

.competences h2,
.jobs h2,
.studies h2,
.projects h2 {
  padding: 50px;
  text-align: center;
  text-transform: uppercase; }
  @media screen and (max-width: 575px) {
    .competences h2,
    .jobs h2,
    .studies h2,
    .projects h2 {
      padding: 30px 10px;
      font-size: 1.8em; } }

header {
  padding: 30px; }
  @media screen and (max-width: 992px) {
    header {
      margin-top: 90px; } }
  @media screen and (max-width: 575px) {
    header {
      padding: 30px 10px; } }

header h1 {
  margin-bottom: 30px; }

header h2 {
  margin-bottom: 20px; }

header .contacts {
  word-break: break-all; }

header .contacts svg,
header .contacts i,
header .contacts span {
  color: #b0bf84; }

@media screen and (max-width: 992px) {
  .contacts-margin-top {
    margin-top: 20px; } }

@media screen and (max-width: 992px) {
  .no-flexbox header .row {
    display: block; } }

nav.desktop {
  background-color: #688718;
  border-width: 0 0 1px 0;
  border-color: #ffffff;
  border-style: solid; }
  @media screen and (max-width: 992px) {
    nav.desktop {
      display: none; } }
  nav.desktop ul {
    position: relative; }
  nav.desktop li {
    float: left;
    padding: 20px;
    font-size: 1.25em;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer; }
  nav.desktop li.js-active,
  nav.desktop li:hover {
    background-color: #4b4f3c; }
  nav.desktop ul:after {
    display: block;
    clear: both;
    content: ""; }

nav.mobile .navigation-access {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  font-size: 1.5em;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #688718;
  color: #ffffff;
  z-index: 1; }

nav.mobile .navigation-button {
  width: 44px;
  height: 44px;
  padding: 3px;
  border-width: 2px;
  border-style: solid;
  border-color: #ffffff;
  border-radius: 5px;
  cursor: pointer; }

nav.mobile .btn-line-nav {
  margin: 7px 4px;
  height: 2px;
  background-color: #ffffff; }

nav.mobile .navigation-display {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #222222;
  color: #ffffff;
  z-index: 2; }

nav.mobile .navigation-display ul {
  position: relative; }

nav.mobile .navigation-display li {
  margin: 30px 0;
  font-size: 1.5em;
  font-weight: bold;
  cursor: pointer; }
  nav.mobile .navigation-display li::after {
    display: block;
    content: "";
    clear: both; }
  nav.mobile .navigation-display li > i {
    display: block;
    float: left;
    margin-right: 20px;
    width: 30px; }
  nav.mobile .navigation-display li > div {
    float: left;
    width: 80%; }

nav.mobile .navigation-close,
nav.mobile .navigation-items {
  padding: 20px; }

nav.mobile .navigation-close {
  font-size: 1.5em;
  font-weight: bold;
  text-transform: uppercase;
  background: #688718; }

nav.mobile .navigation-close-btn {
  padding: 7px 10px;
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
  cursor: pointer; }

.competences {
  display: block;
  padding: 0 30px;
  background-color: #f5f5f5; }
  @media screen and (max-width: 575px) {
    .competences {
      padding: 0 10px; } }
  @media screen and (max-width: 992px) {
    .competences {
      display: none;
      margin-top: 90px; } }

.competences h3 {
  margin: 30px;
  font-size: 2em;
  font-weight: bold;
  color: #222222; }
  @media screen and (max-width: 575px) {
    .competences h3 {
      font-size: 1.5em; } }

.competences-description {
  margin-bottom: 30px; }

.competences-description div {
  padding: 35px 25px;
  border-radius: 3px;
  background-color: #ffffff;
  height: 100%; }
  @media screen and (max-width: 575px) {
    .competences-description div {
      padding: 30px 10px; } }

.competences-description ul {
  margin: 0 auto;
  width: 80%;
  text-align: left; }
  .competences-description ul li {
    padding-top: 20px; }

.no-flexbox .competences .row {
  display: block; }
  .no-flexbox .competences .row::after {
    display: block;
    content: "";
    clear: both; }
  .no-flexbox .competences .row .competences-description {
    display: inline-block;
    vertical-align: top;
    max-width: 33.1%; }
    @media screen and (max-width: 1200px) {
      .no-flexbox .competences .row .competences-description {
        max-width: 49.8%; } }
    @media screen and (max-width: 992px) {
      .no-flexbox .competences .row .competences-description {
        max-width: 100%; } }

.jobs {
  display: none;
  padding: 0 30px;
  background-color: #b0bf84; }
  @media screen and (max-width: 575px) {
    .jobs {
      padding: 0 10px; } }
  @media screen and (max-width: 992px) {
    .jobs {
      margin-top: 90px; } }

.jobs h2 {
  color: #222222; }

.jobs-details {
  margin-bottom: 20px; }

.jobs-details > div {
  background-color: #ffffff;
  border-radius: 3px; }

.jobs-details h3 {
  padding: 20px;
  font-size: 1.2em;
  font-weight: bold;
  color: #ffffff;
  background-color: #4b4f3c;
  border-radius: 3px 3px 0 0; }

.jobs-details h3 > svg,
.jobs-details h3 > i {
  margin-right: 15px; }

.jobs-description > div {
  padding: 20px; }

.jobs-enterprise {
  color: #688718; }

.jobs-description > div > ul {
  position: relative;
  padding: 20px; }

.jobs-description > div > ul > li {
  padding: 5px; }

.jobs-description .activity::before {
  display: none;
  font-family: "Font Awesome 5 Solid";
  content: "\f105"; }

.jobs-description .activity > svg,
.jobs-description .activity > i {
  position: absolute;
  top: auto;
  left: 0;
  margin-top: 3px; }

.jobs-description > div > ul > li > svg,
.jobs-description > div > ul > li > i {
  margin-right: 10px;
  color: #688718; }

.jobs-environnement > div {
  height: 100%;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 0 0 3px 0; }
  @media screen and (max-width: 768px) {
    .jobs-environnement > div {
      border-radius: 0 0 3px 3px; } }

.jobs-environnement ul {
  padding: 10px; }

.jobs-environnement li {
  padding-top: 10px; }

.no-flexbox .jobs > .container {
  padding-bottom: 20px; }

.no-flexbox .jobs .row {
  display: block; }
  .no-flexbox .jobs .row::after {
    display: block;
    content: "";
    clear: both; }
  .no-flexbox .jobs .row .jobs-description,
  .no-flexbox .jobs .row .jobs-environnement {
    float: left; }

.studies {
  display: none;
  padding: 0 30px; }
  @media screen and (max-width: 575px) {
    .studies {
      padding: 0 10px; } }
  @media screen and (max-width: 992px) {
    .studies {
      margin-top: 90px; } }

.studies h3 {
  margin: 20px;
  font-size: 1.5em;
  font-weight: bold; }

.studies-description {
  margin-bottom: 20px; }

.studies-description div {
  height: 100%;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 3px; }

.no-flexbox .studies .row {
  display: block; }
  .no-flexbox .studies .row::after {
    display: block;
    content: "";
    clear: both; }
  .no-flexbox .studies .row .studies-description {
    display: inline-block;
    vertical-align: top;
    max-width: 33.1%; }
    @media screen and (max-width: 992px) {
      .no-flexbox .studies .row .studies-description {
        max-width: 100%; } }

.projects {
  display: none;
  padding: 0 30px;
  background-color: #f5f5f5; }
  @media screen and (max-width: 575px) {
    .projects {
      padding: 0 10px; } }
  @media screen and (max-width: 992px) {
    .projects {
      margin-top: 90px; } }

.projects h3,
.projects-year {
  margin: 30px 30px 0 30px;
  font-size: 2em;
  font-weight: bold;
  color: #222222; }
  @media screen and (max-width: 575px) {
    .projects h3,
    .projects-year {
      font-size: 1.5em; } }

.projects-year {
  margin: 0 30px 30px 30px;
  font-size: 1.5em; }

.projects-description {
  margin-bottom: 30px; }

.projects-description div {
  height: 100%;
  padding: 35px;
  background-color: #ffffff;
  border-radius: 3px; }
  @media screen and (max-width: 992px) {
    .projects-description div {
      min-height: auto; } }
  @media screen and (max-width: 575px) {
    .projects-description div {
      padding: 30px 10px; } }

.projects-description-details {
  color: #688718; }

.projects-realisation {
  margin: 0 auto;
  width: 80%;
  text-align: left; }

.no-flexbox .projects .row {
  display: block; }
  .no-flexbox .projects .row::after {
    display: block;
    content: "";
    clear: both; }
  .no-flexbox .projects .row .projects-description {
    display: inline-block;
    vertical-align: top;
    max-width: 33.1%; }
    @media screen and (max-width: 992px) {
      .no-flexbox .projects .row .projects-description {
        max-width: 100%; } }

footer {
  padding: 30px;
  background-color: #4b4f3c;
  color: #ffffff; }
  @media screen and (max-width: 575px) {
    footer {
      padding: 30px 10px; } }
  footer .row:first-child {
    padding-bottom: 30px; }

.copyright {
  text-align: center; }

.conception-tools div {
  padding: 20px;
  text-align: center;
  background-color: #222222;
  border-radius: 3px; }

.conception-tools svg,
.conception-tools i,
.conception-tools img {
  margin: 10px 10px 0 10px; }

.conception-tools .bootstrap {
  width: 30px;
  height: 30px;
  border-width: 1px;
  border-color: #ffffff;
  border-style: solid;
  border-radius: 3px;
  vertical-align: -.125em; }

.conception-tools .jquery {
  width: 30px;
  height: 30px;
  vertical-align: -.125em; }

.conception-tools .handlebars {
  width: 30px;
  height: auto;
  vertical-align: top;
  margin-top: 22px; }

.conception-tools .json {
  width: 50px;
  height: auto;
  vertical-align: top;
  margin-top: 18px; }

.conception-tools .browsersync {
  width: auto;
  height: 30px;
  vertical-align: -.125em; }

.no-flexbox img.json {
  margin: 0 10px;
  width: 50px;
  height: 50px; }

.no-flexbox img.browsersync {
  margin: 0 10px;
  width: 30px;
  height: 30px; }
