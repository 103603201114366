.competences {
    display: block;
    padding: 0 30px;
    background-color: $colorLightGrey;
    @include maxWidth($extraSmallBreak){
        padding: 0 10px; 
    }
    @include maxWidth($largeBreak){
        display: none;
        margin-top: 90px;
    }
}

.competences h3 {
    margin: 30px;
    font-size: 2em;
    font-weight: bold;
    color: $colorMainFont;
    @include maxWidth($extraSmallBreak){
        font-size: 1.5em;
    }
}

.competences-description {
    margin-bottom: 30px;
}

.competences-description div {  
    padding: 35px 25px;
    border-radius: 3px;
    background-color: $colorWhite;
    height: 100%;
    @include maxWidth($extraSmallBreak){        
        padding: 30px 10px; 
    }
}

.competences-description ul {
    margin: 0 auto;
    width: 80%;
    text-align: left;
    li {
        padding-top: 20px;
    }
}

.no-flexbox .competences .row {
    display: block;
    &::after {
        @include mixinClear();
    }
    .competences-description {
        display: inline-block;
        vertical-align: top;
        max-width: 33.1%;
        @include maxWidth($extraLargeBreak){
            max-width: 49.8%;
        }
        @include maxWidth($largeBreak){
            max-width: 100%;
        }
    }
}