footer {
    padding: 30px;
    background-color: $colorDarkGreen;
    color: $colorWhite;
    @include maxWidth($extraSmallBreak){
        padding: 30px 10px; 
    }
    .row:first-child {
        padding-bottom: 30px;
    }
}

.copyright {
    text-align: center;
}

.conception-tools div {
    padding: 20px;
    text-align: center;
    background-color: $colorMainFont;
    border-radius: 3px;
}

.conception-tools svg,
.conception-tools i,
.conception-tools img {
    margin: 10px 10px 0 10px;
}

.conception-tools .bootstrap {
    width: 30px;
    height: 30px;
    border-width: 1px;
    border-color: $colorWhite;
    border-style: solid;
    border-radius: 3px;
    vertical-align: -.125em;
}

.conception-tools .jquery {
    width: 30px;
    height: 30px;
    vertical-align: -.125em;
}

.conception-tools .handlebars {
    width: 30px;
    height: auto;
    vertical-align: top;
    margin-top: 22px;
}

.conception-tools .json {
    width: 50px;
    height: auto;
    vertical-align: top;
    margin-top: 18px;
}

.conception-tools .browsersync {
    width: auto;
    height: 30px;
    vertical-align: -.125em;
}

.no-flexbox {
    img.json {
        margin: 0 10px;
        width: 50px;
        height: 50px;
    }
    img.browsersync {
        margin: 0 10px;
        width: 30px;
        height: 30px;
    }
}